//console.log("custom js file loaded");
$(function () {
	$('[data-toggle="tooltip"]').tooltip();
});



function IsEmail(email) {
  var regex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if(!regex.test(email)) {
    return false;
  }else{
    return true;
  }
}

function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

$(document).on('turbolinks:load', function (){


	$('.select2-no-search').select2({
		minimumResultsForSearch: Infinity,
		placeholder: 'Choose one'
	});
	$('.rangeslider5').ionRangeSlider({
		type: 'single',
		grid: true,
    values: ["2015-2021", 2015, 2016, 2017, 2018, 2019, 2020, 2021],
    from: "2015-2021",
		prettify_enabled: false
	});
  lot_range = $('.rangeslider5').data("ionRangeSlider");
	$('.rangeslider4').ionRangeSlider({
		type: 'double',
		grid: true,
		min: 2015,
		max: 2021,
		input_values_separator: "-",
		prettify_enabled: false
	});
	$('.rangeslider3').ionRangeSlider({
		type: 'double',
		grid: true,
		min: 2010,
		max: 2021,
		input_values_separator: "-",
		prettify_enabled: false
	});
	$('.rangeslider0').ionRangeSlider({
		type: 'double',
		grid: true,
		min: 1980,
		max: 2021,
		input_values_separator: "-",
		prettify_enabled: false
	});
	$('.rangeslider1').ionRangeSlider({
		type: 'double',
		grid: true,
		min: 0,
		max: 48,
		input_values_separator: "-",
		prettify_enabled: false
	});
	$('.rangeslider2').ionRangeSlider({
		type: 'double',
		grid: true,
		min: 0,
		max: 100,
		input_values_separator: "-",
		prettify_enabled: false
	});

	$(".filter-apply").on('click', function(){
		var f = {}
		 f["k"] = $("#fk-select input[type=hidden i]").val();
		 f["b"] = $("#fb-select input[type=hidden i]").val();
		 f["r"] = $("#fr-select input[type=hidden i]").val();
		 f["t"] = $( "#ft option:selected" ).val();
		 f["s"] = $( "#fs option:selected" ).val();
		 f["l"] = $( "#fl option:selected" ).val();
		 f["v"] = $( "#fv option:selected" ).val();
		 f["o"] = $( "#fo option:selected" ).val();
		 f["i"] = $( "#fi" ).val();
		 f["y"] = $( "#fy" ).val();
		 f["g"] = $( "#fg" ).val();
		 f["m"] = $( "#fm" ).val();
		var pars = []
		Object.entries(f).forEach((entry) => {
		  const [k, v] = entry;
		  if (typeof v !== 'undefined' && v !== "0") pars.push("f"+k+ "="+v);
		});

		$.ajax({
			url: "?"+pars.join('&'),
			processData: false,
  		  	contentType: false,
  		  	dataType: 'script',
			success: function(r){

			}
		});
	});

	$("#new_phone").on('keyup', '.new_phone_field', function(){
		var thenum = ""
		thenum = $(".new_phone_field").val().replace(/[^0-9]/g, '');
		//var len = thenum.length();
		if (thenum.length < 3){
			var newval = "+" + thenum
		}else if (thenum.length < 5){
			var newval = "+" + thenum.substr(0, 2) + " (" + thenum.substr(2, 3)
		}else{
			var newval = "+" + thenum.substr(0, 2) + " (" + thenum.substr(2, 3) + ") " + thenum.substr(5, 3) + " " + thenum.substr(8, 4);
		}
		newval = newval.trim();
		$(".new_phone_field").val(newval);
		if (thenum.length > 11){
			$(".new_phone_submit").attr("disabled", false);
			$(".new_phone_submit").css('cursor', 'pointer');
			$(".new_phone_field").removeClass('parsley-error');
			$(".new_phone_field").addClass('parsley-success');
		};
		if (thenum.length < 12){
			$(".new_phone_submit").attr("disabled", true);
			$(".new_phone_submit").css('cursor', 'not-allowed');
			$(".new_phone_field").removeClass('parsley-success');
			$(".new_phone_field").addClass('parsley-error');
		};
	});


	$("#new_phone").on('keypress', '.new_phone_field', function(e){
		//console.log("keypress");
	  if (e.which == 13) {
		var enternotice = {};
		enternotice['en'] = 'Please enter 12-digits phone number';
		enternotice['ua'] = 'Введіть 12 цифр номера телефону';
		enternotice['ru'] = 'Введите 12-значный номер телефона';
		toastr.error(enternotice[window.locale]);
//		e.preventDefault();
	    return false;
	  }
	});


	$("#new_email").on('keyup', '.new_email_field', function(){
		var email = $(".new_email_field").val();
		if(IsEmail(email)==false){
			$(".new_email_submit").attr("disabled", true);
			$(".new_email_submit").css('cursor', 'not-allowed');
			$(".new_email_field").removeClass('parsley-success');
			$(".new_email_field").addClass('parsley-error');
		}else{
			$(".new_email_submit").attr("disabled", false);
			$(".new_email_submit").css('cursor', 'pointer');
			$(".new_email_field").removeClass('parsley-error');
			$(".new_email_field").addClass('parsley-success');
		}
	});

	$("#new_email").on('keypress', '.new_email_field', function(e){

	  if (e.which == 13) {
  		var email = $(".new_email_field").val();
  		if(IsEmail(email)==false){
			var enternotice = {};
			enternotice['en'] = 'Please enter correct email address';
			enternotice['ua'] = 'Введіть правильну адресу електронної пошти';
			enternotice['ru'] = 'Введите правильный адрес электронной почты';
			toastr.error(enternotice[window.locale]);
	//		e.preventDefault();
		    return false;
  		}
	  }
	});


	$("#new_url").on('keyup', '.new_url_field', function(){
		var url = $(".new_url_field").val();
		if(isValidHttpUrl(url)==false){
			$(".new_url_submit").attr("disabled", true);
			$(".new_url_submit").css('cursor', 'not-allowed');
			$(".new_url_field").removeClass('parsley-success');
			$(".new_url_field").addClass('parsley-error');
		}else{
			$(".new_url_submit").attr("disabled", false);
			$(".new_url_submit").css('cursor', 'pointer');
			$(".new_url_field").removeClass('parsley-error');
			$(".new_url_field").addClass('parsley-success');
		}
	});

	$("#new_url").on('keypress', '.new_url_field', function(e){

	  if (e.which == 13) {
  		var url = $(".new_url_field").val();
  		if(isValidHttpUrl(url)==false){
			var enternotice = {};
			enternotice['en'] = 'Please enter correct website address';
			enternotice['ua'] = 'Введіть правильну адресу web-сайту';
			enternotice['ru'] = 'Введите правильный адрес web-сайта';
			toastr.error(enternotice[window.locale]);
	//		e.preventDefault();
		    return false;
  		}
	  }
	});

	$("#point_flag_legend").on('click', function(e){
		if($('#point_flag').attr('visibility') == "hidden"){
			$('#point_flag').attr('visibility', 'visible');
		}else{
			$('#point_flag').attr('visibility', 'hidden');
		}

	});


	$(".show-all-awards").on('click', function(e){
		e.preventDefault();
		$(".hidden-awards").removeClass("d-none");
		$("#show-all-awards").addClass("d-none");
	});

});
