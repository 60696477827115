$(function(){
  'use strict'

  // This template is mobile first so active menu in navbar
  // has submenu displayed by default but not in desktop
  // so the code below will hide the active menu if it's in desktop
  if(window.matchMedia('(min-width: 992px)').matches) {
    $('.co-navbar .active').removeClass('show');
    $('.co-header-menu .active').removeClass('show');
  }

  // Shows header dropdown while hiding others
  $('.co-header .dropdown > a').on('click', function(e) {
    e.preventDefault();
    $(this).parent().toggleClass('show');
    $(this).parent().siblings().removeClass('show');
  });

  // Showing submenu in navbar while hiding previous open submenu
  $('.co-navbar .with-sub').on('click', function(e) {
    e.preventDefault();
    $(this).parent().toggleClass('show');
    $(this).parent().siblings().removeClass('show');
  });

  // this will hide dropdown menu from open in mobile
  $('.dropdown-menu .co-header-arrow').on('click', function(e){
    e.preventDefault();
    $(this).closest('.dropdown').removeClass('show');
  });

  // this will show navbar in left for mobile only
  $('#coNavShow, #coNavbarShow').on('click', function(e){
    e.preventDefault();
    $('body').addClass('co-navbar-show');
  });

  // this will hide currently open content of page
  // only works for mobile
  $('#coContentLeftShow').on('click touch', function(e){
    e.preventDefault();
    $('body').addClass('co-content-left-show');
  });

  // This will hide left content from showing up in mobile only
  $('#coContentLeftHide').on('click touch', function(e){
    e.preventDefault();
    $('body').removeClass('co-content-left-show');
  });

  // this will hide content body from showing up in mobile only
  $('#coContentBodyHide').on('click touch', function(e){
    e.preventDefault();
    $('body').removeClass('co-content-body-show');
  })

  // navbar backdrop for mobile only
  $('body').append('<div class="co-navbar-backdrop"></div>');
  $('.co-navbar-backdrop').on('click touchstart', function(){
    $('body').removeClass('co-navbar-show');
  });

  // Close dropdown menu of header menu
  $(document).on('click touchstart', function(e){
    e.stopPropagation();

    // closing of dropdown menu in header when clicking outside of it
    var dropTarg = $(e.target).closest('.co-header .dropdown').length;
    if(!dropTarg) {
      $('.co-header .dropdown').removeClass('show');
    }

    // closing nav sub menu of header when clicking outside of it
    if(window.matchMedia('(min-width: 992px)').matches) {

      // Navbar
      var navTarg = $(e.target).closest('.co-navbar .nav-item').length;
      if(!navTarg) {
        $('.co-navbar .show').removeClass('show');
      }

      // Header Menu
      var menuTarg = $(e.target).closest('.co-header-menu .nav-item').length;
      if(!menuTarg) {
        $('.co-header-menu .show').removeClass('show');
      }

      if($(e.target).hasClass('co-menu-sub-mega')) {
        $('.co-header-menu .show').removeClass('show');
      }

    } else {

      //
      if(!$(e.target).closest('#coMenuShow').length) {
        var hm = $(e.target).closest('.co-header-menu').length;
        if(!hm) {
          $('body').removeClass('co-header-menu-show');
        }
      }
    }

  });

  $('#coMenuShow').on('click', function(e){
    e.preventDefault();
    $('body').toggleClass('co-header-menu-show');
  })

  $('.co-header-menu .with-sub').on('click', function(e){
    e.preventDefault();
    $(this).parent().toggleClass('show');
    $(this).parent().siblings().removeClass('show');
  })

  $('.co-header-menu-header .close').on('click', function(e){
    e.preventDefault();
    $('body').removeClass('co-header-menu-show');
  })

});
