// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import Rails from "@rails/ujs"
global.Rails = Rails;
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import 'bootstrap'
import 'src/pagy.js.erb'
//import '../stylesheets/application'
//import 'ionicons'
//import 'ionicons.suuqn5vt'
//global.toastr = require("toastr")
global.toastr = require("src/toastr")
require("src/custom")

require("src/jquery.flot")
require("src/jquery.flot.pie")
require("src/jquery.flot.resize")
require("src/jquery.sparkline.min")
//require("src/jquery.stellar.min")
require("src/select2.min")
require("src/ion.rangeSlider.min")
require("src/style")


Rails.start()
Turbolinks.start()
ActiveStorage.start()

